<template>
  <Card>
    <template #title>
      <h3>{{ _t('label_General_info') }}</h3>
    </template>
    <template #content>
      <div class="p-d-flex p-flex-row p-jc-between">
        <div class="p-d-inline-flex p-text-bold" style="min-width: 100px;">{{ _t('menu.version') }}:</div>
        <div v-if="getGeneralInfo && getGeneralInfo.version" class="p-d-inline-flex">{{ getGeneralInfo.version }}</div>
        <Skeleton v-else class="p-d-inline-flex"/>
      </div>
      <div class="p-d-flex p-flex-row p-jc-between">
        <div class="p-d-inline-flex p-text-bold" style="min-width: 100px;">{{ _t('label_status') }}:</div>
        <div v-if="getGeneralInfo && getGeneralInfo.status && getGeneralInfo.status.status" class="p-d-inline-flex">
          <Badge :severity="indicator( getGeneralInfo.status.status )" class="p-mt-1"></Badge>
        </div>
        <Skeleton v-else class="p-d-inline-flex"/>
      </div>
      <div class="p-d-flex p-flex-row p-jc-between">
        <div class="p-d-inline-flex p-text-bold" style="min-width: 100px;">{{ _t('label_Web_services_alive') }}:</div>
        <div v-if="getGeneralInfo && getGeneralInfo.status" class="p-d-inline-flex">
          <Badge :severity="indicator(getGeneralInfo.status.webServicesAlive)" class="p-mt-1"></Badge>
        </div>
        <Skeleton v-else class="p-d-inline-flex"/>
      </div>
      <div class="p-d-flex p-flex-row p-jc-between">
        <div class="p-d-inline-flex p-text-bold" style="min-width: 100px;">{{ _t('label_Active_alarms') }}</div>
        <div v-if="getGeneralInfo && getGeneralInfo.activeAlarms >= 0" class="p-d-inline-flex">
          {{ getGeneralInfo.activeAlarms }}
        </div>
        <Skeleton v-else class="p-d-inline-flex"/>
      </div>
      <div class="p-d-flex p-flex-row p-jc-between">
        <div class="p-d-inline-flex p-text-bold" style="min-width: 100px;">{{ _t('label_Pending_alarms') }}</div>
        <div v-if="getGeneralInfo && getGeneralInfo.pendingAlarms >= 0" class="p-d-inline-flex">
          {{ getGeneralInfo.pendingAlarms }}
        </div>
        <Skeleton v-else class="p-d-inline-flex"/>
      </div>
      <div class="p-d-flex p-flex-row p-jc-between">
        <div v-if="getGeneralInfo && getGeneralInfo.modules.length" class="p-d-inline-flex">
          <ul class="p-ml-0 p-pl-0" style="list-style: none;"><strong>{{ _t('label_Modules') }}:</strong>
            <li v-for="(module, index) in getGeneralInfo.modules" :key="index">
              <Badge :severity="indicator(module.status)"></Badge>
              {{ module.module }}
              <small v-if="module.devices.length"><i>({{ module.devices.join(', ').toLowerCase() }})</i></small>
            </li>
          </ul>
        </div>
        <Skeleton v-else class="p-d-inline-flex"/>
      </div>
    </template>
  </Card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "general-info",
  title: 'label_General_info',
  preview: 'assets/layout/images/widgets/general.png',
  isLoading: false,
  configuration: {},
  mounted() {
    this.callGeneralInfo();
  },
  methods: {
    ...mapActions({
      'callGeneralInfo': 'dashboard/callGeneralInfo',
    }),
    indicator(status) {
      switch (status) {
        case 'ACTV':
        case 'GOING_ALIVE':
        case 'ACTIVE':
          return 'success';
        case 'STOPPED':
          return 'danger';
        case 'PASV':
        case 'WARNING':
        case 'UNKNOWN':
          return 'warning';
        case 'MAINTENANCE':
          return 'info'
        default:
          return 'danger';
      }
    },
  },
  computed: {
    ...mapGetters({
      'getGeneralInfo': 'dashboard/getGeneralInfo',
    }),
  },
}
</script>

<style scoped>

</style>
